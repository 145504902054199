import { EnumClientTypes } from '../../enums';
import { IFormClientRegistrationPCR } from '../../services';

export const initialFormClientRegistrationPCRValues: IFormClientRegistrationPCR = {
  client_type: EnumClientTypes.Private,
  created_at: '',
  id: '',
  first_name: '',
  last_name: '',
  personal_code: '',
  birthdate: '',
  address: {
    country: '',
    municipality: '',
    city: '',
    street: '',
    house: '',
    flat: '',
    post_code: '',
  },
  email: '',
  phone: '',
  website: '',
};
