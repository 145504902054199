import { ReactNode, createContext, useContext, useState } from 'react';

import { helpersAxios } from '../helpers';
import { TApiAuthMeResponse } from '../services';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const token = sessionStorage.getItem('token');

interface AuthContextType {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
  auth: any;
  setAuth: (user: any) => void;
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

interface IProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: IProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [auth, setAuth] = useState<TApiAuthMeResponse | null>(null);

  const login = (token: string) => {
    helpersAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    sessionStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
    helpersAxios.defaults.headers.common['Authorization'] = undefined;
    sessionStorage.removeItem('token');
  };

  const setUser = (user: TApiAuthMeResponse) => {
    setAuth(user);
  };

  return <AuthContext.Provider value={{ isAuthenticated, login, logout, auth, setAuth: setUser }}>{children}</AuthContext.Provider>;
};
