import { useQuery } from '@tanstack/react-query';

import { apiPaths } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiClassifiersGet } from './intefaces';

const useGetClassifiers = () => {
  return useQuery({
    queryKey: ['getClassifiers'],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.classifiers.getClassifiers);

      return res.data as IApiClassifiersGet[];
    },
  });
};

export const servicesClassifiers = {
  getClassifiers: useGetClassifiers,
};
