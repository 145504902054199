import { Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';

import loginImage from '../../assets/login-page.png';
import logoutImage from './../../assets/logout-page.png';

interface IProps {
  type: 'login' | 'logout';
}

export const PageLoader = ({ type }: IProps) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  return (
    <div
      className="w-full min-h-screen flex items-center justify-center"
      style={{ backgroundColor: token.colorBgLayout }}
    >
      <div>
        <img
          src={type === 'login' ? loginImage : logoutImage}
          alt="loading"
        />
        <div className="animate-bounce text-center">
          <Typography.Title level={4}>{t(type === 'login' ? 'PageLoader.Login' : 'PageLoader.Logout')}</Typography.Title>
        </div>
      </div>
    </div>
  );
};
