import { useMutation, useQuery } from '@tanstack/react-query';

import { apiPaths } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiClientsGet, IFormClientRegistrationBCR, IFormClientRegistrationPCR } from './intefaces';

const useGetClients = () => {
  return useQuery({
    queryKey: ['useGetClients'],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.clients.getClients);

      return res.data as IApiClientsGet[];
    },
  });
};

const usePostBusiness = () => {
  return useMutation({
    mutationFn: async (data: IFormClientRegistrationBCR) => {
      const res = await helpersAxios(apiPaths.clients.postBusiness, {
        method: 'POST',
        data: data,
      });

      return res as any;
    },
  });
};

const usePostPrivate = () => {
  return useMutation({
    mutationFn: async (data: IFormClientRegistrationPCR) => {
      const res = await helpersAxios(apiPaths.clients.postPrivate, {
        method: 'POST',
        data: data,
      });

      return res as any;
    },
  });
};

const useGetClient = (clientId: string) => {
  return useQuery({
    queryKey: ['useGetClient', clientId],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.clients.getClient(clientId));

      return res.data as IApiClientsGet[];
    },
  });
};

export const servicesClients = {
  getClients: useGetClients,
  postBusiness: usePostBusiness,
  postPrivate: usePostPrivate,
  getClient: useGetClient,
};
