import dayjs from 'dayjs';

interface IProps {
  value: string;
  format?: 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm' | 'YYYY-MM-DD HH:mm:ss';
}

export const Date = ({ value, format = 'YYYY-MM-DD HH:mm:ss' }: IProps) => {
  return dayjs(value).format(format);
};
