import { DatePicker, DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import { useController } from 'react-hook-form';

import { getNestedObject } from '../../../helpers';
import { FieldsError } from '../Error.tsx';
import { FieldsLabel } from '../Label.tsx';

interface IPros extends DatePickerProps {
  name: string;
  label?: string;
}

export const FieldsDate = ({ name, label, ...restProps }: IPros) => {
  const { field, formState } = useController({
    name: name,
    shouldUnregister: true,
  });

  const error = getNestedObject<{ message: string } | undefined>(formState.errors, name);

  return (
    <div className="mb-4">
      <FieldsLabel label={label} />
      <DatePicker
        status={error !== undefined ? 'error' : ''}
        className="w-full"
        onChange={(_value, valueString) => field.onChange(valueString)}
        value={field.value ? dayjs(field.value) : undefined}
        {...restProps}
      />
      <FieldsError error={error?.message} />
    </div>
  );
};
