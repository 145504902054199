import { Alert, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { routerPaths } from '../../consts';
import { useAuth } from '../../contexts';
import { TApiAuthLoginRequestBody, servicesAuth } from '../../services';
import { FieldsInput, FieldsPassword, Form } from '../../shared';

export const FormLogin = () => {
  const { mutateAsync, error, isError } = servicesAuth.postLogin();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const validationSchema = z.object({
    email: z.string().trim().min(10),
    password: z.string().trim().min(1, t('Validations.Required')),
  });

  const handleSubmit = async (values: TApiAuthLoginRequestBody) => {
    try {
      const res = await mutateAsync(values);

      login(res.data.access_token);
      navigate(routerPaths.dashboard);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={{
        email: 'test@test.test',
        password: 'password',
      }}
    >
      {({ formState }) => (
        <>
          <FieldsInput
            name="email"
            label={t('Validations.Labels.Email')}
            size="large"
          />
          <FieldsPassword
            name="password"
            label={t('Validations.Labels.Password')}
            size="large"
          />
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block={true}
            loading={formState.isSubmitting}
          >
            {t('Validations.Buttons.Login')}
          </Button>
          {isError && (
            <Alert
              className="mt-4"
              // banner={true}
              type="error"
              showIcon={true}
              description={error?.status === 401 ? 'Check login information' : 'Error'}
            />
          )}
        </>
      )}
    </Form>
  );
};
