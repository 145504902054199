import { EnumClientTypes } from '../../enums';
import { IFormClientRegistrationBCR } from '../../services';

export const initialFormClientRegistrationBCRValues: IFormClientRegistrationBCR = {
  client_type: EnumClientTypes.Business,
  created_at: '',
  id: '',
  title: '',
  registration_country: '',
  registration_date: '',
  company_code: '',
  vat_code: '',
  legal_entity: '',
  address: {
    country: '',
    municipality: '',
    city: '',
    street: '',
    house: '',
    flat: '',
    post_code: '',
  },
  email: '',
  phone: '',
  website: '',
};
