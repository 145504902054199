import { useQuery } from '@tanstack/react-query';

import { apiPaths } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiUsersGet } from './intefaces';

const useGetUsers = () => {
  return useQuery({
    queryKey: ['getUsers'],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.users.getUsers);

      return res.data as IApiUsersGet[];
    },
  });
};

export const servicesUsers = {
  getUsers: useGetUsers,
};
