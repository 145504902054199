import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { apiPaths } from '../../consts';
import { helpersAxios } from '../../helpers';
import { operations } from '../../interfaces';

export type TApiAuthLoginRequestBody = operations['auth.login']['requestBody']['content']['application/json'];
type TApiAuthLoginResponse = operations['auth.login']['responses']['200']['content']['application/json'];
type TApiAuthLoginResponseError = operations['auth.login']['responses']['422']['content']['application/json'];

export const usePostLogin = () => {
  return useMutation<AxiosResponse<TApiAuthLoginResponse>, AxiosError<TApiAuthLoginResponseError>, TApiAuthLoginRequestBody>({
    mutationFn: async (data: TApiAuthLoginRequestBody): Promise<AxiosResponse<TApiAuthLoginResponse>> => {
      return await helpersAxios.post(apiPaths.auth.login, data);
    },
  });
};

type TApiAuthLogoutResponse = operations['auth.logout']['responses']['200']['content']['application/json'];

export const usePostLogout = () => {
  return useMutation<AxiosResponse<TApiAuthLogoutResponse>, AxiosError>({
    mutationFn: async (): Promise<AxiosResponse<TApiAuthLogoutResponse>> => {
      return await helpersAxios.post(apiPaths.auth.logout);
    },
  });
};

type TApiAuthRefreshResponse = operations['auth.refresh']['responses']['200']['content']['application/json'];

const usePostRefresh = () => {
  return useMutation<AxiosResponse<TApiAuthRefreshResponse>, AxiosError>({
    mutationFn: async (): Promise<AxiosResponse<TApiAuthRefreshResponse>> => {
      return await helpersAxios.post(apiPaths.auth.refresh, {
        method: 'POST',
      });
    },
  });
};

export type TApiAuthMeResponse = operations['auth.me']['responses']['200']['content']['application/json'];

const useGetMe = () => {
  return useQuery<AxiosResponse<TApiAuthMeResponse>, AxiosError>({
    queryKey: ['useGetMe'],
    queryFn: async () => await helpersAxios(apiPaths.auth.me),
  });
};

export const servicesAuth = {
  postLogin: usePostLogin,
  postLogout: usePostLogout,
  postRefresh: usePostRefresh,
  getMe: useGetMe,
};
