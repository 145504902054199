import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLoader } from '../../components';
import { routerPaths } from '../../consts';
import { useAuth } from '../../contexts';
import { servicesAuth } from '../../services';

export const PagesLogout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { mutateAsync } = servicesAuth.postLogout();

  const handleLogout = async () => {
    try {
      await mutateAsync();
    } catch (e) {
      console.log(e);
    } finally {
      logout();
      setTimeout(() => navigate(routerPaths.login, { replace: true }), 1000);
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <PageLoader type="logout" />;
};
