import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { PageLoader } from '../../components';
import { routerPaths } from '../../consts';
import { useAuth } from '../../contexts';
import { servicesAuth } from '../../services';

interface ProtectedRouteProps {
  children: JSX.Element | JSX.Element[];
}

export const RoutesAuth = ({ children }: ProtectedRouteProps) => {
  const { data, isFetching } = servicesAuth.getMe();
  const { isAuthenticated, setAuth } = useAuth();

  useEffect(() => {
    if (data?.data) {
      setAuth(data.data);
    }
  }, [data]);

  if (!isAuthenticated) {
    return (
      <Navigate
        to={routerPaths.login}
        replace={true}
      />
    );
  }

  if (isFetching) {
    return <PageLoader type="login" />;
  }

  return children;
};
