import { QueryObserverOptions, useMutation, useQuery } from '@tanstack/react-query';

import { apiPaths } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiTemplates } from './intefaces';

const useGetTemplates = () => {
  return useQuery({
    queryKey: ['useGetTemplates'],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.templates.getTemplates);

      return res.data as IApiTemplates[];
    },
  });
};

const usePostTemplates = () => {
  return useMutation({
    mutationFn: async (data: IApiTemplates) => {
      const res = await helpersAxios(apiPaths.templates.postTemplates, {
        method: 'POST',
        data: data,
      });

      return res as any;
    },
  });
};

const useGetTemplate = (templateId: string, params: Omit<QueryObserverOptions<IApiTemplates>, 'queryKey' | 'queryFn'> = {}) => {
  return useQuery({
    queryKey: ['useGetTemplate', templateId],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.templates.getTemplate(templateId));

      return res.data as IApiTemplates;
    },
    ...params,
  });
};

export const servicesTemplates = {
  getTemplates: useGetTemplates,
  postTemplates: usePostTemplates,
  getTemplate: useGetTemplate,
};
