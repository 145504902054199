import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Skeleton } from 'antd';
import 'ckeditor5/ckeditor5.css';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { routerPaths, styles } from 'src/consts';

import { CKEditorCustom } from '../../components';
import { IApiTemplates, servicesTemplates } from '../../services';
import { FieldsInput, FieldsSelect, FieldsTextarea, Form } from '../../shared';

const TemplateClassifierSchema = z.object({
  classifier: z.string(),
  label: z.string(),
});

const TemplateSchema = z.object({
  title: z.string().trim().min(2).max(255),
  content: z.string(),
  description: z.string().trim().min(2).max(600),
  signMethod: z.enum(['none', 'manual', 'electronic', 'both']),
  classifiers: z.array(TemplateClassifierSchema),
});

interface IParams {
  [key: string]: string | undefined;
}

export const PagesTemplatesCreate = () => {
  const { id }: IParams = useParams();
  const navigate = useNavigate();
  const { mutateAsync } = servicesTemplates.postTemplates();
  const { t } = useTranslation();
  const { data, isFetching } = servicesTemplates.getTemplate(id ?? '', {
    enabled: !!id,
  });

  const handleSubmit = async (values: IApiTemplates) => {
    const res = await mutateAsync(values);

    if (res.status === 201) {
      navigate(routerPaths.templates.index);
    }
  };

  if (isFetching) {
    return <Skeleton />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={
        data ?? {
          title: '',
          content: '',
          description: '',
          signMethod: 'none',
          classifiers: [],
        }
      }
      validationSchema={TemplateSchema}
    >
      {({ watch }) => (
        <Row
          gutter={styles.gutter.default}
          className="h-full"
          align="stretch"
          wrap={false}
        >
          <Col flex="auto">
            <Card className="mb-4">
              <FieldsInput
                name="title"
                prefix={<EditOutlined />}
                size="large"
                variant="borderless"
                placeholder={t('TemplatesCreate.Labels.PlaceHolder')}
                className="font-bold"
                wrapperClassName="mb-0"
              />
            </Card>
            <Card>
              <div className="main-container">
                <CKEditorCustom
                  name="content"
                  initialData={
                    '<p>asd asdas dasd&nbsp;<span class="classifier" data-classifier="business.basic.registrationCountry" data-label="Business > Basic > RegistrationCountry"></span> asdasdas dasd asd</p>'
                  }
                />
              </div>
            </Card>
            <pre className="text-wrap">{JSON.stringify(watch(), undefined, 2)}</pre>
          </Col>
          <Col style={{ width: 320, minWidth: 320 }}>
            <Card className="h-full">
              <FieldsSelect
                name="signMethod"
                options={[
                  { label: t('TemplatesCreate.Options.None'), value: 'none' },
                  { label: t('TemplatesCreate.Options.Manual'), value: 'manual' },
                  { label: t('TemplatesCreate.Options.Electronic'), value: 'electronic' },
                  { label: t('TemplatesCreate.Options.Both'), value: 'both' },
                ]}
                label={t('TemplatesCreate.Labels.SignMethod')}
              />
              <FieldsTextarea
                name="description"
                autoSize={{ minRows: 3, maxRows: 3 }}
                label={t('TemplatesCreate.Labels.Description')}
                maxLength={600}
              />
              <Button
                type="primary"
                block={true}
                htmlType="submit"
              >
                {t('TemplatesCreate.Buttons.Submit')}
              </Button>
            </Card>
          </Col>
        </Row>
      )}
    </Form>
  );
};
