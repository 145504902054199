import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { AuthProvider } from './contexts';
import { helpersAxios } from './helpers';
import { AppRoutes } from './routes';

function App() {
  const [api, contextHolder] = notification.useNotification();

  helpersAxios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.status !== 401 && error.status !== 422) {
        api.error({
          type: 'error',
          message: 'Error',
          description: error.message,
        });
      }
      return Promise.reject(error);
    },
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
      {contextHolder}
    </QueryClientProvider>
  );
}

export default App;
