import { Button, Col, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { styles } from 'src/consts';
import { FieldsDate, FieldsInput, FieldsSelect, Form } from 'src/shared';

import { IFormClientRegistrationBCR, servicesClients } from '../../services';
import { initialFormClientRegistrationBCRValues } from './initialValues.ts';

export const FormClientRegistrationBCR = () => {
  const { mutateAsync } = servicesClients.postBusiness();
  const { t } = useTranslation();

  const handleSubmit = async (data: IFormClientRegistrationBCR) => {
    const res = await mutateAsync(data);
    console.log(res);
  };

  const validationSchema = z.object({
    title: z.string().trim().min(1),
    registration_country: z.string().trim().min(1),
    registration_date: z.string().trim().min(1),
    legal_entity: z.string().trim().min(1),
    vat_code: z.string().trim().min(1),
    company_code: z.string().trim().min(1),
    country: z.string().trim().min(1),
    municipality: z.string().trim().min(1),
    city: z.string().trim().min(1),
    street: z.string().trim().min(1),
    house: z.string().trim().min(1),
    post_code: z.string().trim().min(1),
    email: z.string().email().min(1),
    phone: z.string().trim().min(1),
  });

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={initialFormClientRegistrationBCRValues}
    >
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('ClientRegistrationBusiness.Titles.Basic')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.Title')}
            name="title"
          />
        </Col>
        <Col span={12}>
          <FieldsSelect
            label={t('ClientRegistrationBusiness.Labels.Country')}
            name="registration_country"
            options={[
              {
                label: t('ClientRegistrationBusiness.Options.Lithuania'),
                value: 'LT',
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <FieldsDate
            label={t('ClientRegistrationBusiness.Labels.Date')}
            name="registration_date"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.CompanyCode')}
            name="company_code"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.VATCode')}
            name="vat_code"
          />
        </Col>
        <Col span={24}>
          <FieldsSelect
            label={t('ClientRegistrationBusiness.Labels.LegalEntity')}
            name="legal_entity"
            options={[
              {
                label: t('ClientRegistrationBusiness.Options.LegalEntity'),
                value: 'LLC',
              },
            ]}
          />
        </Col>
      </Row>
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('ClientRegistrationBusiness.Titles.Address')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsSelect
            label={t('ClientRegistrationBusiness.Labels.Country')}
            name="country"
            options={[
              {
                label: t('ClientRegistrationBusiness.Options.Lithuania'),
                value: 'LT',
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.Municipality')}
            name="municipality"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.City')}
            name="city"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.Street')}
            name="street"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.House')}
            name="house"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.Flat')}
            name="flat"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.PostCode')}
            name="post_code"
          />
        </Col>
      </Row>
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('ClientRegistrationBusiness.Titles.Contacts')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.Email')}
            name="email"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.Phone')}
            name="phone"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationBusiness.Labels.Website')}
            name="website"
          />
        </Col>
      </Row>
      <Flex justify="end">
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('ClientRegistrationBusiness.Buttons.Submit')}
        </Button>
      </Flex>
    </Form>
  );
};
