import axios from 'axios';

const token = sessionStorage.getItem('token');

export const helpersAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

helpersAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
helpersAxios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';
