import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { routerPaths } from '../../consts';
import { EnumClientTypes } from '../../enums';
import { FieldsInput, FieldsSelect, Form } from '../../shared';
import { initialFormClientRegistrationPCRValues } from '../FormClientRegistrationPCR';

export const FormSchemaCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (data: any) => {
    console.log(data);
    navigate(routerPaths.schemas.preview.replace(':id', '223232'));
  };

  const validationSchema = z.object({
    private: z.object({
      basic: z.object({
        name: z.string().trim().min(1, { message: 'Required' }),
        surname: z.string().trim().min(1),
        birthday: z.string().trim().min(1, { message: 'Select date' }),
        personCode: z.string().trim().min(1),
        citizenship: z.string().trim().min(1),
      }),
      address: z.object({
        country: z.string().trim().min(1),
        municipality: z.string().trim().min(1),
        city: z.string().trim().min(1),
        street: z.string().trim().min(1),
        house: z.string().trim().min(1),
        postCode: z.string().trim().min(1),
      }),
      contacts: z.object({
        email: z.string().email().min(1),
        phone: z.string().trim().min(1),
      }),
    }),
  });

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialFormClientRegistrationPCRValues}
    >
      <FieldsSelect
        options={[
          {
            label: t('Enums.ClientTypes.ALL'),
            value: EnumClientTypes.All,
          },
          {
            label: t('Enums.ClientTypes.PCR'),
            value: EnumClientTypes.Private,
          },
          {
            label: t('Enums.ClientTypes.BCR'),
            value: EnumClientTypes.Private,
          },
        ]}
        label={t('SchemaCreate.Labels.Type')}
        name="type"
      />

      <FieldsInput
        label={t('SchemaCreate.Labels.Title')}
        name="title"
      />
      <FieldsInput
        label={t('SchemaCreate.Labels.Description')}
        name="description"
      />
      <Flex justify="end">
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('SchemaCreate.Buttons.Submit')}
        </Button>
      </Flex>
    </Form>
  );
};
