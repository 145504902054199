import { Button, Col, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { styles } from '../../consts';
import { IFormClientRegistrationPCR, servicesClients } from '../../services';
import { FieldsDate, FieldsInput, FieldsSelect, Form } from '../../shared';
import { initialFormClientRegistrationPCRValues } from './initialValues';

export const FormClientRegistrationPCR = () => {
  const { t } = useTranslation();
  const { mutateAsync } = servicesClients.postPrivate();

  const handleSubmit = async (data: IFormClientRegistrationPCR) => {
    const res = await mutateAsync(data);
    console.log(res);
  };

  const validationSchema = z.object({
    first_name: z.string().trim().min(1, { message: 'Required' }),
    last_name: z.string().trim().min(1),
    birthdate: z.string().trim().min(1, { message: 'Select date' }),
    personal_code: z.string().trim().min(1),
    citizenship: z.string().trim().min(1),
    country: z.string().trim().min(1),
    municipality: z.string().trim().min(1),
    city: z.string().trim().min(1),
    street: z.string().trim().min(1),
    house: z.string().trim().min(1),
    post_code: z.string().trim().min(1),
    email: z.string().email().min(1),
    phone: z.string().trim().min(1),
  });

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={initialFormClientRegistrationPCRValues}
    >
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('ClientRegistrationPerson.Titles.Basic')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.Name')}
            name="first_name"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.LastName')}
            name="last_name"
          />
        </Col>
        <Col span={12}>
          <FieldsDate
            label={t('ClientRegistrationPerson.Labels.Birthday')}
            name="birthdate"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.PersonCode')}
            name="personal_code"
          />
        </Col>
        <Col span={24}>
          <FieldsSelect
            label={t('ClientRegistrationPerson.Labels.Citizenship')}
            name="citizenship"
            options={[
              {
                label: t('ClientRegistrationPerson.Options.Lithuania'),
                value: 'LT',
              },
            ]}
          />
        </Col>
      </Row>
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('ClientRegistrationPerson.Titles.Address')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsSelect
            label={t('ClientRegistrationPerson.Labels.Country')}
            name="country"
            options={[
              {
                label: t('ClientRegistrationPerson.Options.Lithuania'),
                value: 'LT',
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.Municipality')}
            name="municipality"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.City')}
            name="city"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.Street')}
            name="street"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.House')}
            name="house"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.Flat')}
            name="flat"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.PostCode')}
            name="post_code"
          />
        </Col>
      </Row>
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('ClientRegistrationPerson.Titles.Contacts')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.Email')}
            name="email"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.Phone')}
            name="phone"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('ClientRegistrationPerson.Labels.Website')}
            name="website"
          />
        </Col>
      </Row>
      <Flex justify="end">
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('ClientRegistrationPerson.Buttons.Submit')}
        </Button>
      </Flex>
    </Form>
  );
};
