import { useQuery } from '@tanstack/react-query';

import { apiPaths } from '../../consts';
import { helpersAxios } from '../../helpers';
import { IApiPermissionsGet } from './intefaces';

const useGetPermissions = () => {
  return useQuery({
    queryKey: ['getPermissions'],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.permissions.getPermissions);

      return res.data as IApiPermissionsGet[];
    },
  });
};

export const servicesPermissions = {
  getPermissions: useGetPermissions,
};
