export const apiPaths = {
  auth: {
    login: 'auth/login',
    logout: 'auth/logout',
    refresh: 'auth/refresh',
    me: 'auth/me',
  },
  clients: {
    getClients: 'clients',
    getClient: (clientId: string) => `clients/${clientId}`,
    postBusiness: 'clients/business',
    postPrivate: 'clients/private',
  },
  templates: {
    getTemplates: 'templates',
    getTemplate: (templateId: string) => `templates/${templateId}`,
    postTemplates: 'templates',
  },
  schemas: {
    getSchemas: 'schemas',
  },
  permissions: {
    getPermissions: 'permissions',
  },
  users: {
    getUsers: 'users',
  },
  classifiers: {
    getClassifiers: 'classifiers',
  },
};
