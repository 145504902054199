import { zodResolver } from '@hookform/resolvers/zod';
import { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { ZodSchema } from 'zod';

interface IProps<T extends FieldValues> {
  children: ((form: UseFormReturn<T>) => ReactNode) | ReactNode;
  onSubmit: (data: T, methods: UseFormReturn<T>) => Promise<void>;
  initialValues: T;
  validationSchema?: ZodSchema;
}

export const Form = <T extends FieldValues>({ children, initialValues, validationSchema, onSubmit }: IProps<T>) => {
  const methods = useForm<T>({
    values: initialValues,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(async (data: T) => {
          await onSubmit(data, methods);
        })}
      >
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  );
};
