import { IClientAddress } from '../../services';

interface IProps {
  values: IClientAddress;
}

export const Address = ({ values }: IProps) => {
  const flat = values.flat ? `-${values.flat}` : '';

  return `${values.street} ${values.house}${flat}, ${values.city}, ${values.municipality}, ${values.country}, ${values.post_code}`;
};
