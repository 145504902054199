import { Button, Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { routerPaths } from '../../consts';
import { servicesClients } from '../../services';

interface IParams {
  [key: string]: string | undefined;
}

export const PagesClientsPreview = () => {
  const navigate = useNavigate();
  const { id }: IParams = useParams();
  const { data, isFetching } = servicesClients.getClient(id ?? '');

  return (
    <div>
      <Button onClick={() => navigate(routerPaths.clients.index)}>Back</Button>
      {isFetching ? <Skeleton /> : <pre>{JSON.stringify(data, undefined, 2)}</pre>}
    </div>
  );
};
